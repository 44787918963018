import React, { Component } from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import defaultAvatar from '../images/avatar.png'
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap"
import {
  ALERT_MSG_ERROR,
  AUTH_TOKEN_KEY,
  DOMAIN_API,
  FR,
  getUserLanguage,
  NL,
  translate,
  URL_ADD_AVATARIMG_USER,
  URL_DELETE_AVATARIMG_USER,
  URL_GET_MAINPARTNERSUPER_DATA,
  URL_GET_NACEBELCODES,
  URL_GET_USER_FULL,
  URL_IS_AUTH,
  URL_MODIFY_USER
} from "../utils"
import axios from "axios"
import UserInterestsModal from "../components/userInterestsModal"
import UserPartnerModal from "../components/userPartnerModal"
import ModalAlert from "../components/modalAlert"

//code activité

class PartnerProfile extends Component{
  state = {
    email: "",
    avatarImg: '',
    name: "",
    description: "",
    descriptionFR: "",
    emailsPresubscr: [],
    headerImg: null,
    partner: null,
    partnerCollabs: [],
    id: null,
    isDisabled: true,
    userLanguage: NL,
    showAlert: false,
    alertTitle: '',
    alertMessage: '',
  }

  componentDidMount = async () => {
    const userLanguage = getUserLanguage();
    if (userLanguage) await this.setState({userLanguage})
    await this.auth()
    await this.getSuperPartner()
  }

  patchUserData = () => {
    console.log('patch')
    const {prenom_1,nom_1,rue,numero_de_maison_ajout,
      lieu,numero_de_telephone,numero_d_enterprise,nom_de_commerce} = this.state;
    const entries = Object.entries(this.state)
    let newObject = {
      prenom_1,
      nom_1,
      rue,
      numero_de_maison_ajout,
      lieu,
      numero_de_telephone,
      numero_d_enterprise,
      nom_de_commerce,
    }
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const header = {
      headers: { Authorization: `Bearer ${token}` }
    };
    const data = newObject;
    axios.patch(URL_MODIFY_USER,data,header)
      .then(res => {
        console.log(res.data)
        window.location.reload();
      })
      .catch(err => {
        if (err.response && err.response.data) {
          console.log(err.response.data);
          this.setState({
            showAlert: true,
            alertTitle: ALERT_MSG_ERROR,
            alertMessage: err.response.data.message,
          })
        }
        console.log(err)
      });
  }

  getSuperPartner = () => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const data = {
      headers: { Authorization: `Bearer ${token}` }
    };
    axios.get(URL_GET_MAINPARTNERSUPER_DATA,data)
      .then(res => {
        if (res.data) {
          console.log('result',res.data)
          const {name,description,descriptionFR,emailsPresubscr,
            headerImg,id,partner,partnerCollabs,avatarImg} = res.data
          this.setState({
            name,description,descriptionFR,emailsPresubscr,
            headerImg,id,partner,partnerCollabs,avatarImg
          })
        }
      })
      .catch(err => {
        if (err.response && err.response.data) {
          console.log(err.response.data.message)
        }
        console.log(err)
      })
  }

  fileSelectedHandler = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0]
      const fd = new FormData();
      fd.append('avatarImg',file,file.name)
      const token = localStorage.getItem(AUTH_TOKEN_KEY)
      const header = {
        headers: { Authorization: `Bearer ${token}` },
      }
      axios.delete(URL_DELETE_AVATARIMG_USER,header)
        .then(res => {
          if (res.data) {
            console.log(res.data)
          }
          axios.post(URL_ADD_AVATARIMG_USER,fd,header)
            .then(res => {
              if (res.data) {
                console.log(res.data)
              }
              console.log('update succesfull')
              window.location.reload();
            })
            .catch(err => {
              if (err.response && err.response.data) {
                console.log(err.response.data);
                this.setState({
                  showAlert: true,
                  alertTitle: ALERT_MSG_ERROR,
                  alertMessage: err.response.data.message,
                })
              }
              console.log(err)
              setTimeout(() => window.location.reload(),3000)
            })
        })
        .catch(err => {
          if (err.response && err.response.data) {
            console.log(err.response.data);
            this.setState({
              showAlert: true,
              alertTitle: ALERT_MSG_ERROR,
              alertMessage: err.response.data.message,
            })
          }
          console.log(err)
        })
    }
  }

  auth = () => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const data = {
      headers: { Authorization: `Bearer ${token}` },
    }
    axios
      .get(URL_IS_AUTH, data)
      .then(res => {
        if (res.data) {
          const { id, email, role } = res.data
          this.setState({ accountId: id })
          this.setState({ email: email })
          this.setState({ role: role })
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data)
        } else console.log(err)
        window.location.href = "/login"
      })
  }

  render() {
    const {
      email,
      avatarImg,
      name,
      description,
      descriptionFR,
      emailsPresubscr,
      headerImg,
      partner,
      partnerCollabs,
      id,
      isDisabled,
      userLanguage,
      showAlert,
      alertTitle,
      alertMessage,
    } = this.state;

    return (
      <Layout>
        <div className="bg-white">
          <Header />
          <Container className="auther-profile-wrap mb-5 pb-5">
            <Row>
              <Col xl="3">
                <div className="d-flex justify-content-center justify-content-xl-end">
                  <div className="auther-profile mb-4" style={{position: 'relative'}}>
                    <img
                      //onClick={() => alert('Coming soon!')}
                      className="rounded-circle img-fluid"
                      src={(avatarImg!=="")?DOMAIN_API()+avatarImg:defaultAvatar}
                      alt=""
                    />
                    <input
                      style={{position: 'absolute',width:'100%',height: '100%',borderRadius: '65px',opacity:'0',cursor: 'pointer'}}
                      type={'file'}
                      onChange={e => this.fileSelectedHandler(e)}
                    />
                  </div>
                </div>
              </Col>
              <Col xl="7">
                <h1 className="h1-title text-center text-xl-left">Profile Partner</h1>
                {((headerImg) && (headerImg!== ''))?
                  <img style={{width: '100%'}}  src={DOMAIN_API()+headerImg} />
                :null}
                <Form>
                  <Row>
                    <Col xl="12">
                      <h4 className="h4-title">{translate("GEBRUIKERS INFORMATIE",false,false)}</h4>
                    </Col>
                    {/*<Col md="6">
                      <Form.Group>
                        <Form.Label>E-mail adres</Form.Label>
                        <Form.Control type="text" placeholder="jesse@example.com" />
                      </Form.Group>
                    </Col>*/}
                    <Col md="6">
                      <Form.Group>
                        <Form.Label>{translate("E-mail adres",false)}</Form.Label>
                        <Form.Control
                          disabled={true}
                          value={email}
                          onChange={(e) => this.setState({email: e.target.value})}
                          type="text"
                          //placeholder="jesse@example.com"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <Form.Label>{translate('voornaam')}</Form.Label>
                        <Form.Control
                          //value={prenom_1}
                          //onChange={(e) => this.setState({prenom_1: e.target.value})}
                          disabled={isDisabled} type="text" /*placeholder="Jesse"*/ />
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <Form.Label>{'naam'}</Form.Label>
                        <Form.Control
                          //value={nom_1}
                          //onChange={(e) => this.setState({nom_1: e.target.value})}
                          disabled={isDisabled} type="text" /*placeholder="Lucky"*/ />
                      </Form.Group>
                    </Col>
                    {/*<Col md="6">
                    <Form.Group>
                      <Form.Label>Username</Form.Label>
                      <Form.Control type="text" placeholder="lucky.jesse" />
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group>
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="jesse@example.com"
                      />
                    </Form.Group>
                  </Col>*/}

                    <Col xl="12">
                      <h4 className="h4-title">{translate('CONTACTGEGEVENS',false,false)}</h4>
                    </Col>
                    <Col md="8">
                      <Form.Group>
                        <Form.Label>{translate('adres')}</Form.Label>
                        <Form.Control
                          //value={rue}
                          //onChange={(e) => this.setState({rue: e.target.value})}
                          disabled={isDisabled}
                          type="text"
                          //placeholder={translate("nationale Gulden Laan")}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>{translate('nummer')}</Form.Label>
                        <Form.Control
                          //value={numero_de_maison_ajout}
                          //onChange={(e) => this.setState({numero_de_maison_ajout: e.target.value})}
                          disabled={isDisabled} type="text"
                          //placeholder={translate('nummer')}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group>
                        <Form.Label>{translate("stad")}</Form.Label>
                        <Form.Control
                          //value={lieu}
                          //onChange={(e) => this.setState({lieu: e.target.value})}
                          disabled={isDisabled} type="text"
                          //placeholder="Ixelles"
                        />
                      </Form.Group>
                    </Col>
                    {/*<Col md="6">
                      <Form.Group>
                        <Form.Label>Regio</Form.Label>
                        <Form.Control
                          value={region}
                          onChange={(e) => this.setState({region: e.target.value})}
                          disabled={isDisabled} type="text" placeholder="Brussels" />
                      </Form.Group>
                    </Col>*/}
                    <Col md="6">
                      <Form.Group>
                        <Form.Label>{translate('telefoonnummer')}</Form.Label>
                        <Form.Control
                          //value={numero_de_telephone}
                          //onChange={(e) => this.setState({numero_de_telephone: e.target.value})}
                          disabled={isDisabled} type="text"
                          //placeholder="0484 90 43 30"
                        />
                      </Form.Group>
                    </Col>
                    {/*<Col md="6">
                      <Form.Group>
                        <Form.Label>Tweede telefoonnummer</Form.Label>
                        <Form.Control
                          value={phone2}
                          onChange={(e) => this.setState({phone2: e.target.value})}
                          disabled={isDisabled} type="text" placeholder="0498 23 13 20" />
                      </Form.Group>
                    </Col>*/}
                    <Col xl="12">
                      <h4 className="h4-title">{translate("BEDRIJFSINFORMATIE",false,false)}</h4>
                    </Col>
                    <Col md="8">
                      <Form.Group>
                        <Form.Label>{translate('bedrijfsnaam')}</Form.Label>
                        <Form.Control
                          //value={nom_de_commerce}
                          //onChange={(e) => this.setState({nom_de_commerce: e.target.value})}
                          disabled={isDisabled} type="text"
                          //placeholder="Luckystike"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label>{translate("bedrijfnummer")}</Form.Label>
                        <Form.Control
                          //value={numero_d_enterprise}
                          //onChange={(e) => this.setState({numero_d_enterprise: e.target.value})}
                          disabled={isDisabled}
                          type="text"
                          //placeholder="BE0843 2222 3333"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      {/*<Form.Group onClick={() => alert('Coming soon!')}>
                        <Form.Label>Sector</Form.Label>
                        <Form.Control disabled={true} type="text" placeholder="Sector" />
                      </Form.Group>*/}
                      <Form.Group>
                        <Form.Label>{translate("beschrijving van de activiteit")}</Form.Label>
                        <Form.Control disabled={true} /*value={activityDescription}*/ type="text" placeholder={translate('activiteitscode',false)} />
                      </Form.Group>
                    </Col>
                    {/*<Col md="6">
                      <Form.Group onClick={() => alert('Coming soon!')} >
                        <Form.Label>Interesses</Form.Label>
                        <Form.Control value={interests} disabled={true} type="text" placeholder="Lucky" />
                      </Form.Group>
                    </Col>*/}
                    <Col xl="12">
                      <Button
                        disabled={true}
                        className="form-btn"
                        variant="success"
                        onClick={() => this.setState({isDisabled: !isDisabled})}
                        //type="submit"
                      >
                        {translate('klik om te bewerken')}
                      </Button>
                      <Button
                        disabled={true}
                        onClick={() => this.patchUserData()}
                        className="form-btn"
                        variant="primary"
                        //type="submit"
                      >
                           {translate('Opslaan')}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
        {(showAlert)?
          <ModalAlert
            alertTitle={alertTitle}
            alertMessage={alertMessage}
            closeModal={() => this.setState({showAlert: false})}
          />:null
        }
      </Layout>
    )
  }
}

export default PartnerProfile
